import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "advanced-transitions"
    }}>{`Advanced transitions`}</h1>
    <p>{`This is where shit gets fun.`}</p>
    <p>{`You can drive React state changes with D3 transitions. 🤯`}</p>
    <p>{`The idea is that:`}</p>
    <ol>
      <li parentName="ol">{`Your components are a pure representation of state+props`}</li>
      <li parentName="ol">{`Change state lots and components animate`}</li>
      <li parentName="ol">{`D3 transitions are a javascript game loop inside`}</li>
      <li parentName="ol">{`Use the transition to change state, drive renders`}</li>
    </ol>
    <p>{`You can build stuff like this:`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/hzKOMXi.gif",
        "alt": null
      }}></img></p>
    <p>{`But we're going to do something simpler 👉 a bouncy scatterplot.`}</p>
    <p>{`Take your final scatterplot from before and make its size transition into smaller dimensions on click.`}</p>
    <p>{`You'll need to:`}</p>
    <ol>
      <li parentName="ol">{`Change the click handling function`}</li>
      <li parentName="ol">{`Use a custom `}<inlineCode parentName="li">{`.tween`}</inlineCode>{` transition to hook into D3's game loop`}</li>
      <li parentName="ol">{`Use that tween to update dimension state`}</li>
    </ol>
    <p>{`Here are some tips:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`.tween()`}</inlineCode>{` calls a function that should return a parametrized function`}</li>
      <li parentName="ul">{`that function will get a `}<inlineCode parentName="li">{`t`}</inlineCode>{` which says `}<em parentName="li">{`"We're on this frame of animation"`}</em></li>
      <li parentName="ul">{`you can use `}<inlineCode parentName="li">{`d3.interpolate`}</inlineCode>{` to parametrize between 2 values`}</li>
      <li parentName="ul">{`your parametrized method can update state to trigger renders 😉`}</li>
    </ul>
    <p>{`Here's my final solution, I'll walk you through after you give it a shot:`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/react-d3-scatterplot-bouncy-i6hr6",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      